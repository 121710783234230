import React from 'react';

const StatoTapparella = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_aux ? "red" : "gray" }} /> Allarme AUX</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "gray" }} /> Supervisione</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.escluso_led ? "red" : "gray" }} /> Escluso</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "gray" }} /> Dormiente</p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.memoria_aux ? "#ffc20a" : "gray" }} /> Memoria AUX</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "gray") }} /> Batteria Scarica</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.guasto ? "red" : "gray" }} /> Guasto</p>
                </div>
            </div>
        </>
    );
};

export default StatoTapparella;