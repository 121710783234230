import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bAutoIns } from '../../../../lib/goldParser/converter';

import {
    goldDoRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldAutoIns: (requests, payloads) => dispatch(goldDoRequest(requests, payloads))
});

const _Autoinserimento = ({ data, errors, system, goldAutoIns }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [current, setCurrent] = useState(!!_system.store ? _system.store.pm.autoins : null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);
    const [insTotale, setInsTotale] = useState(0);
    const [ins1, setIns1] = useState(0);
    const [ins2, setIns2] = useState(0);
    const [ins3, setIns3] = useState(0);
    const conf = [
        { name: "Totale", key: "totale", method: setInsTotale, value: insTotale },
        { name: "G1", key: "g1", method: setIns1, value: ins1 },
        { name: "G2", key: "g2", method: setIns2, value: ins2 },
        { name: "G3", key: "g3", method: setIns3, value: ins3 }
    ];

    useEffect(() => {
        if (current) {
            setInsTotale(current.totale.tempo);
            setIns1(current.g1.tempo);
            setIns2(current.g2.tempo);
            setIns3(current.g3.tempo);
            setTimeout(() => setFirstLoad(true), 100);
        }
        return () => null;
    }, [current, firstLoad]);

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            totale: { tempo: insTotale, abilitato: form.abilitato_totale.checked },
            g1: { tempo: ins1, abilitato: form.abilitato_g1.checked },
            g2: { tempo: ins2, abilitato: form.abilitato_g2.checked },
            g3: { tempo: ins3, abilitato: form.abilitato_g3.checked }
        };
    };

    const handleReset = e => {
        e.preventDefault();
        setFirstLoad(false);
        setCurrent(null);
        setTimeout(() => setCurrent(_system.store.pm.autoins), 100);
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldAutoIns(['goldWriteAutoIns'], [{
            id_centrale: system.id_centrale,
            edata: j2bAutoIns(payload)
        }]);
        setProcessed(true);
    }

    if (!!!firstLoad) {
        return <>caricamento in corso...</>;
    }

    if (data && processed) {
        setProcessed(null);
        setSuccess(!!data && !errors);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <h3>Autoinserimento</h3>
                            <br />
                            <fieldset>
                                <div className="rack">
                                    {conf.map((value, idx) => (
                                        <div className="w50" key={`autoinserimento_${value.key}`}>
                                            <div className="rack">
                                                <div className="w50">
                                                    <p><strong>Autoinserimento {value.name}</strong></p>
                                                    <ReactSlider
                                                        defaultValue={value.value}
                                                        onChange={e => { value.method(e); }}
                                                        max={1439}
                                                        min={0}
                                                        className="horizontal-slider"
                                                        thumbClassName="slider-thumb"
                                                        trackClassName="slider-track"
                                                        renderThumb={(props, state) => {
                                                            const [hrs, min] = [
                                                                Math.floor(parseInt(state.valueNow) / 60),
                                                                (parseInt(state.valueNow) % 60)
                                                            ];
                                                            return (<div {...props}>{`${(hrs.toString().padStart(2, '0'))}:${(min.toString().padStart(2, '0'))}`}</div>);
                                                        }}
                                                    />
                                                </div>
                                                <div className="w50">
                                                    <div className="switchButton">
                                                        <p>&nbsp;</p>
                                                        <input type="checkbox" className="switch" id={`abilitato_${idx}`} name={`abilitato_${value.key}`} defaultValue="0" defaultChecked={current[value.key].abilitato} onChange={() => null} />
                                                        <label htmlFor={`abilitato_${idx}`}>
                                                            <span>
                                                                <span />
                                                            </span>
                                                            Abilitato
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>Annulla</button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>Applica</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Autoinserimento = connect(mapStateToProps, mapDispatchToProps)(_Autoinserimento);

export default Autoinserimento;