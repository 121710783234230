import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import {
    errorRead,
    doRequest,
    logout
} from '../../../actions';

import Error from '../../elements/Error';
import Outcome from '../../elements/Outcome';
import SchemaValidator from '../../../lib/SchemaValidator';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    doRequestEmailChange: payload => dispatch(doRequest('emailChange', payload)),
    doRequestConfirmEmailChange: payload => dispatch(doRequest('emailChangeConfirm', payload)),
    doRequestDismissEmailChange: () => dispatch(doRequest('emailChangeDismiss')),
    doRequestPasswordChange: payload => dispatch(doRequest('passwordChange', payload)),
    logout: () => dispatch(logout())
});

const _Settings = ({ data, logout, errorRead, doRequestEmailChange, doRequestConfirmEmailChange, doRequestDismissEmailChange, doRequestPasswordChange, stateError, user }) => {
    const initialPayload = {
        new_email: "",
        confirm_email: "",
        password: "",
        new_password: "",
        confirm_password: "",
        code: "",
    };

    const [errors, setErrors] = useState("");
    const [firstTab, setFirstTab] = useState(true);
    const [localError, setLocalError] = useState("");
    const [processed1, setProcessed1] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [processedPassword, setProcessedPassword] = useState(false);
    const [success1, setSuccess1] = useState(!!user.change_email);
    const [successPassword, setSuccessPassword] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const codeField = useRef(null);
    const firstField = useRef(null);
    const secondField = useRef(null);
    const thirdField = useRef(null);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    useEffect(
        () => {
            if (success1) {
                codeField.current.value = '';
            }
            return () => null;
        },
        [success1]
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const isDisabled = () => !(payload.password.length > 0 && payload.new_email.length > 0 && payload.confirm_email.length > 0);
    const isDisabled2 = () => !(payload.password.length > 0 && payload.new_password.length > 0 && payload.confirm_password.length > 0);
    const isDisabled3 = () => !(payload.code.length > 0);

    const tabHandler = value => {
        firstField.current.value = '';
        secondField.current.value = '';
        thirdField.current.value = '';
        setFirstTab(value);
    }

    const handleChangeEmail = (e) => {
        e.preventDefault();
        const _payload = {
            new_email: payload.new_email,
            password: payload.password,
        }
        if (payload.new_email !== payload.confirm_email) {
            setLocalError("I due indirizzi email non coincidono");
        } else {
            setLocalError("");
            let v = SchemaValidator(_payload, 'emailchange');
            if (v.errors.length > 0) {
                setLocalError(v.errors.map(el => el.schema.message).join("\n"));
            } else {
                setLocalError("");
                setProcessed1(true);
                doRequestEmailChange(_payload);
            }
        }
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        const _payload = {
            password: payload.new_password,
            password_old: payload.password,
        }
        if (payload.new_password !== payload.confirm_password) {
            setLocalError("Le due password non coincidono");
        } else {
            setLocalError("");
            let v = SchemaValidator(_payload, 'passwordchange');
            if (v.errors.length > 0) {
                setLocalError(v.errors.map(el => el.schema.message).join("\n"));
            } else {
                setLocalError("");
                setProcessedPassword(true);
                doRequestPasswordChange(_payload);
            }
        }
    }

    const handleConfirmChangeEmail = (e) => {
        e.preventDefault();
        const _payload = {
            code: payload.code,
        }
        setProcessed2(true);
        doRequestConfirmEmailChange(_payload);
    }

    const handleDismissChangeEmail = (e) => {
        e.preventDefault();
        setProcessed3(true);
        doRequestDismissEmailChange();
    }

    if (data && processed1) {
        setErrors("");
        setProcessed1(false);
        setSuccess1(true);
    }

    if (data && processed2) {
        setErrors("");
        setProcessed2(false);
        setSuccess1(false);
        setTimeout(() => { logout() }, 3000);
    }

    if (data && processed3) {
        setErrors("");
        setProcessed3(false);
        setSuccess1(false);
    }

    if (data && processedPassword) {
        setErrors("");
        setProcessedPassword(false);
        setSuccessPassword(true);
        setTimeout(() => { logout() }, 3000);
    }

    const renderEmailDefault = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left" id="form1">
                    <fieldset>
                        <p>
                            <label>Nuova Email</label>
                            <input ref={firstField} type="text" id="email_new" defaultValue="" placeholder="Email" name="new_email" onChange={handleChange} />
                        </p>
                        <p>
                            <label>Conferma Email</label>
                            <input ref={secondField} type="text" placeholder="Conferma Email" name="confirm_email" onChange={handleChange} />
                        </p>
                        <p>
                            <label>Password attuale</label>
                            <input ref={thirdField} type="password" placeholder="Password attuale" name="password" onChange={handleChange} />
                        </p>
                        <Error text={localError} />
                        <Error text={errors} />
                        <p>
                            <button disabled={isDisabled()} className="ok" onClick={handleChangeEmail}>Conferma</button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    Per effettuare il cambio dell'email associata occorre digitare e confermare la nuova email einserire la password attuale.
                </p>
                <p>
                    Dopo aver effettuato la modifica, verrà inviato un codice di conferma alla vostra precedente che dovrete immettere in questa pagina.  alla pagina di login dove dovrà reimmettere la nuova email come 'nome utente'.
                </p>
            </div>
        </div>
    );

    const renderEmailConfirm = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left" id="form2" autocomplete="off">
                    <fieldset>
                        <p>
                            <label>Codice conferma email</label>
                            <input autoComplete="off" ref={codeField} id="email_code" type="text" placeholder="Codice" name="code" onChange={handleChange} defaultValue="" />
                        </p>
                        <Error text={localError} />
                        <p>
                            <button disabled={isDisabled3()} className="ok" onClick={handleConfirmChangeEmail}>Conferma</button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    Per confermare il cambio di email, inserire il codice che é stato inviato.
                </p>
                <p>
                    Se il codice é corretto verrà effettuato il logout dovrà rieffettuare il login utilizzando la nuova email.
                </p>
                <p>
                    Se invece vuole annullare l'operazione, clicchi sul seguente bottone di conferma.
                </p>
                <form className="nopad left">
                    <fieldset>
                        <p>
                            <button className="ok" onClick={handleDismissChangeEmail}>Annulla cambio email</button>
                        </p>
                    </fieldset>
                </form>
            </div>
        </div>
    );

    const renderPasswordDefault = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left">
                    <fieldset>
                        <p>
                            <label>Password attuale</label>
                            <input ref={firstField} type="password" placeholder="Password attuale" name="password" onChange={handleChange} />
                        </p>
                        <p>
                            <label>Nuova password</label>
                            <input ref={secondField} type="password" placeholder="Nuova password" name="new_password" onChange={handleChange} />
                        </p>
                        <p>
                            <label>Conferma password</label>
                            <input ref={thirdField} type="password" placeholder="Conferma password" name="confirm_password" onChange={handleChange} />
                        </p>
                        <Error text={errors} />
                        <Error text={localError} />
                        <p>
                            <button disabled={isDisabled2()} className="ok" onClick={handleChangePassword}>Conferma</button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    Per effettuare il cambio della password associata occorre digitare:
                </p>
                <p>
                    - l'attuale password
                    <br />
                    - la nuova password
                    <br />
                    - conferma della nuova password
                </p>
                <p>
                    Dopo aver effettuato la modifica, verrà reindirizzato alla pagina di login dove dovrà reimmettere la nuova password.
                </p>
            </div>
        </div>
    );

    return (
        <section>
            <div className="innerSection">
                <h3>Impostazioni</h3>
                <div className="rack box">
                    {
                        successPassword && (
                            <Outcome text="Password cambiata con successo. Disconnessione in corso..." type="ok" />
                        )
                    }
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'active' : 'disabled')}`} onClick={() => tabHandler(true)}>Cambio Email</h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'disabled' : 'active')}`} onClick={() => tabHandler(false)}>Cambio Password</h2>
                    </div>
                </div>
                {
                    firstTab
                        ? success1
                            ? renderEmailConfirm()
                            : renderEmailDefault()
                        : renderPasswordDefault()
                }
            </div>
        </section>
    );
};

const Settings = connect(mapStateToProps, mapDispatchToProps)(_Settings);

export default Settings;