import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSlider from 'react-slider';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bFilare } from '../../../../lib/goldParser/converter';
import { b2jFilareStat } from '../../../../lib/goldParser/physicalMap';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldModOutFilare: payload => dispatch(goldDoRequest('goldModOutFilare', payload)),
    setStore: data => dispatch(setStore(data))
});

const _IngressiFilari = ({ match, data, errors, system, goldModOutFilare, setStore, reset_dev_stats }) => {
    const wiredForm = useRef(null);
    const _system = clone(system);
    const [ritardatoChecked, setRitardatoChecked] = useState(false);
    const [percorsoChecked, setPercorsoChecked] = useState(false);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [ingressi, setIngressi] = useState([]);
    const [tempoIngresso, setTempoIngresso] = useState(0);
    const [tempoUscita, setTempoUscita] = useState(0);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(null);
    const [stat, setStat] = useState(b2jFilareStat(0));

    useEffect(
        () => {
            setFirstLoad(true);
            setSub(match.params.sub > 0 ? parseInt(match.params.sub) : 1);
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    const _espansioni = [];
                    for (let i = 0; i < _system.store.pm.bus.length; i++) {
                        if (_system.store.pm.bus[i].num_tipo_periferica === 8) {
                            _espansioni.push(_system.store.pm.bus[i]);
                        }
                    }
                    const _ingressi = [];
                    for (let i = 0; i < _system.store.pm.filari.length; i++) {
                        const offset = _system.model < 5 ? 2 : 5;
                        const _i = i - offset;
                        const expansion = i < offset ? false : (_espansioni[Math.floor(_i / 5)] || null);
                        const line = expansion ? (_i % 5) + 1 : null;
                        _ingressi.push({
                            idx: i,
                            group: Math.floor(i / 16),
                            expansion,
                            line,
                            ..._system.store.pm.filari[i],
                            nome: expansion === null ? 'Non Disponibile' : _system.store.pm.filari[i].nome
                        });
                    }
                    setIngressi(_ingressi);
                    setFirstLoad(false);
                    setCurrent(_ingressi[(sub - 1)]);
                    setStore({
                        polling_requests: ['goldDevStat'],
                        polling_payloads: [{ id_centrale: system.id_centrale, type: 'filare', group: _ingressi[(sub - 1)].group }]
                    });
                    setTempoIngresso(_ingressi[(sub - 1)].tempo_ingresso);
                    setTempoUscita(_ingressi[(sub - 1)].tempo_uscita);
                    setRitardatoChecked(_ingressi[(sub - 1)].ritardato);
                    setPercorsoChecked(_ingressi[(sub - 1)].percorso);
                }, 1000);
            }
            return () => null;
        },
        [firstLoad]
    );

    useEffect(
        () => {
            if (current) {
                const __i = current.idx - (current.group * 16);
                setStat(b2jFilareStat(system.store.dev_stats.filare[current.group][__i]));
            }
        },
        [system.store.dev_stats.filare, reset_dev_stats, current]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = wiredForm.current;
        return {
            nome: form.nome.value,
            test: form.test.checked,
            escluso: form.escluso.checked,
            ronda: form.ronda.checked,
            fuoco: form.fuoco.checked,
            campanello: form.campanello.checked,
            silenzioso: form.silenzioso.checked,
            elettroserratura: form.elettroserratura.checked,
            parzializzabile: form.parzializzabile.checked,
            g1: form.g1.checked,
            g2: form.g2.checked,
            g3: form.g3.checked,
            a: form.a.checked,
            k: form.k.checked,
            ritardato: form.ritardato.checked,
            percorso: form.percorso.checked,
            sempre_attivo: form.sempre_attivo.checked,
            tipologia_ingresso: parseInt(form.tipologia_ingresso.value),
            tempo_trigger: parseInt(form.tempo_trigger.value),
            doppio_impulso: form.doppio_impulso.checked,
            tempo_ingresso: parseInt(tempoIngresso),
            tempo_uscita: parseInt(tempoUscita),
            cicli_autoesclusione: parseInt(form.cicli_autoesclusione.value),
        };
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/wired/${value}`);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldModOutFilare({
            id_centrale: system.id_centrale,
            idx: current.idx,
            edata: j2bFilare(payload)
        });
        setProcessed(true);
    }

    const handleReset = e => {
        e.preventDefault();
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(ingressi[idx]), 100);
    };

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    if (!!!current) {
        return <>caricamento in corso....</>;
    }

    if (data && processed) {
        setProcessed(null);
        if (!errors) {
            const form = wiredForm.current;
            const nome = form.nome.value;
            const _ingressi = clone(ingressi);
            _ingressi[current.idx].nome = nome;
            setIngressi(_ingressi);
        }
        setSuccess(!errors);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={wiredForm}>
                            {current && (
                                <>
                                    <h3>IngressiFilari</h3>
                                    <br />
                                    <h1>Parametri di Ingresso</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>Numero</label>
                                                <select value={current.idx} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        ingressi.map((value, index) => (
                                                            value.expansion !== null || index === (sub - 1)
                                                                ? <option key={`filare_${index}`} value={index}>{`${index + 1} - ${value.nome}`}</option>
                                                                : <React.Fragment key={`filare_${index}`} />
                                                        )
                                                        )}
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>Nome</label>
                                                <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <h4>Programmi</h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={current.g1} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`program_label_g1`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g1}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={current.g2} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`program_label_g2`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g2}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={current.g3} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`program_label_g3`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g3}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <div className="w25">
                                                <p>Tipo di Ingresso</p>
                                                <select defaultValue={current.tipologia_ingresso} name="tipologia_ingresso" disabled={system.store.auth != 2}>
                                                    <option key={`tipologia_ingresso_0`} value={0}>NC</option>
                                                    <option key={`tipologia_ingresso_1`} value={2}>Bilanciato</option>
                                                    <option key={`tipologia_ingresso_2`} value={6}>Doppio Bilanciato</option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>Tempo di Trigger</p>
                                                <select defaultValue={current.tempo_trigger} name="tempo_trigger" disabled={system.store.auth != 2}>
                                                    <option key={`tempo_trigger_0`} value={0}>300ms</option>
                                                    <option key={`tempo_trigger_1`} value={1}>500ms</option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>Numero Allarmi</p>
                                                <select defaultValue={current.cicli_autoesclusione} name="cicli_autoesclusione" disabled={system.store.auth != 2}>
                                                    {
                                                        [...Array(16).keys()].map((value, index) => (
                                                            <option key={`allarmi_${index}`} value={value}>{value || 'infiniti'}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>Indirizzo di espansione</p>
                                                <p>{(current.expansion ? `${current.expansion.nome} - L${current.line}` : `-`)}</p>
                                            </div>

                                        </div>
                                        <div className="rack bottomBorder">
                                            <h4>Opzioni</h4>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="0" defaultChecked={current.escluso} />
                                                    <label htmlFor={`escluso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Escluso
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" defaultChecked={current.silenzioso} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`silenzioso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Silenzioso
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="test" name="test" defaultValue="0" defaultChecked={current.test} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`test`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Test
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" defaultChecked={current.ronda} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`ronda`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Ronda
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="doppio_impulso" name="doppio_impulso" defaultValue="0" defaultChecked={current.doppio_impulso} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`doppio_impulso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Doppio Impulso
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="parzializzabile" name="parzializzabile" defaultValue="0" defaultChecked={current.parzializzabile} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`parzializzabile`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Parzializzabile
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="24h" name="sempre_attivo" defaultValue="0" defaultChecked={current.sempre_attivo} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`24h`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        24h
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ritardato" name="ritardato" defaultValue="0" checked={ritardatoChecked} disabled={system.store.auth !== 2} onChange={e => { let _checked = e.target.checked; setRitardatoChecked(_checked); if (_checked) { setPercorsoChecked(false); } }} />
                                                    <label htmlFor={`ritardato`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Ritardato
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="percorso" name="percorso" defaultValue="0" disabled={system.store.auth !== 2} checked={percorsoChecked} onChange={e => { let _checked = e.target.checked; setPercorsoChecked(_checked); if (_checked) { setRitardatoChecked(false); } }} />
                                                    <label htmlFor={`percorso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Percorso
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>Tempo Ingresso</p>
                                                <ReactSlider
                                                    disabled={system.store.auth != 2}
                                                    defaultValue={current.tempo_ingresso}
                                                    onChange={value => { setTempoIngresso(value); }}
                                                    max={239}
                                                    min={1}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 60),
                                                            parseInt(state.valueNow) % 60,
                                                        ];
                                                        return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                            <div className="w50">
                                                <p>Tempo Uscite</p>
                                                <ReactSlider
                                                    disabled={system.store.auth != 2}
                                                    defaultValue={current.tempo_uscita}
                                                    onChange={value => { setTempoUscita(value); }}
                                                    max={239}
                                                    min={1}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 60),
                                                            parseInt(state.valueNow) % 60,
                                                        ];
                                                        return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Associazioni uscite</h1>
                                    <fieldset>
                                        <div className="rack bottomBorder">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="a" name="a" defaultValue="0" defaultChecked={current.a} disabled={system.store.auth != 2}
                                                    />
                                                    <label htmlFor={`a`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        A
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="k" name="k" defaultValue="0" defaultChecked={current.k} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`k`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        K
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="fuoco" name="fuoco" defaultValue="0" defaultChecked={current.fuoco} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`fuoco`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Fuoco
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="campanello" name="campanello" defaultValue="0" defaultChecked={current.campanello} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`campanello`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Campanello
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" defaultChecked={current.elettroserratura} disabled={system.store.auth != 2} />
                                                    <label htmlFor={`elettroserratura`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Elettroserratura
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>Allarmi</h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100">
                                                <p><i className="fa fa-circle" style={{ color: stat.allarme_24 ? "red" : "gray" }} /> Allarme 24h</p>
                                                <p><i className="fa fa-circle" style={{ color: stat.ingresso_aperto ? "red" : "gray" }} /> Ingresso Aperto</p>
                                                <p><i className="fa fa-circle" style={{ color: stat.ingresso_escluso ? "red" : "gray" }} /> Ingresso Escluso</p>
                                                <p><i className="fa fa-circle" style={{ color: stat.memoria_allarme ? "red" : "gray" }} /> Memoria Allarme 24h</p>
                                                <p><i className="fa fa-circle" style={{ color: stat.memoria_allarme_24 ? "red" : "gray" }} /> Memoria Ingresso Aperto</p>
                                            </div>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={handleReset} disabled={(current.expansion === null)}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit} disabled={((current.expansion === null) || (system.store.auth === 2 && system.store.state.prog_active))}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const IngressiFilari = connect(mapStateToProps, mapDispatchToProps)(_IngressiFilari);

export default IngressiFilari;