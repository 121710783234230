import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';
import { b2jBusStat } from '../../../../lib/goldParser/physicalMap';

import {
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    setStore: data => dispatch(setStore(data)),
});

const _StatoPerifericheBUS = ({ system, setStore, reset_dev_stats }) => {
    const [group, setGroup] = useState(0);
    const [perifericheBUS, setPerifericheBUS] = useState([]);
    const init = useCallback(
        () => {
            let _perifericheBUS = [];
            for (let i = 0; i < system.store.pm.bus.length; i++) {
                let _group = Math.floor(i / 16);
                if(system.store.pm.bus[i].num_tipo_periferica > 0) {
                    _perifericheBUS.push({
                        idx: i,
                        group: _group,
                        stat: b2jBusStat(system.store.dev_stats.bus[_group][i], { num_tipo_periferica: system.store.pm.bus[i].num_tipo_periferica }),
                        ...system.store.pm.bus[i]
                    });
                }
            }
            setPerifericheBUS(_perifericheBUS);
        },
        []
    );

    useEffect(
        () => {
            init();
            return () => null;
        },
        []
    );

    useEffect(
        () => {
            setStore({
                polling_requests: ['goldDevStat'],
                polling_payloads: [{ id_centrale: system.id_centrale, type: 'bus', group: group }]
            });
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [group]
    );

    useEffect(
        () => {
            init();
        },
        [system.store.dev_stats.bus, reset_dev_stats]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h3>Stato Periferiche BUS</h3>
                <br />
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <fieldset>
                                <div className="rack">
                                    <div className="w20">
                                        <label>Scelta gruppo</label>
                                        <select defaultValue={group} name="group" onChange={e => setGroup(parseInt(e.target.value))}>
                                            <option value={0}>Da 1 a 16</option>
                                            {perifericheBUS.length > 16 && <option value={1}>Da 17 a 32</option>}
                                        </select>
                                    </div>
                                </div>    
                            </fieldset>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>Nome Ingresso</th>
                                            <th>Stato</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {perifericheBUS.length > 0 
                                        ? (
                                            <>
                                                {perifericheBUS.map((item, index) => {
                                                    let stato = [];
                                                    if(item.stat.dispositivo_presente != null) {
                                                        stato.push(item.stat.dispositivo_presente ? "Non presente" : "Presente");
                                                    }
                                                    if(!!item.stat.as) { stato.push("AS"); }
                                                    if(!!item.stat.sabotaggio) { stato.push("Sabotaggio"); }
                                                    return (
                                                        item.group === group
                                                        ? (
                                                            <tr key={`row_${index}`}>
                                                                <td><span className="head">Nome Ingresso</span>{item.nome}</td>
                                                                <td><span className="head">Stato</span>{stato.join(", ")}</td>
                                                            </tr>
                                                        ) : (
                                                            <React.Fragment key={`row_${index}`} />
                                                        )
                                                    );
                                                })}    
                                            </>
                                        ) : !!perifericheBUS && perifericheBUS.length === 0 
                                            ? (
                                                <tr>
                                                    <td colSpan={2}>nessun dispositivo</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan={2}>caricamento in corso...</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoPerifericheBUS = connect(mapStateToProps, mapDispatchToProps)(_StatoPerifericheBUS);

export default StatoPerifericheBUS;