import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    errorRead,
    doRequest,
} from '../../../actions';

import Outcome from '../../elements/Outcome';
import SchemaValidator from '../../../lib/SchemaValidator';
import System from '../../elements/Forms/System';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    editSystem: (payload, id) => dispatch(doRequest('editSystem', { payload, id })),
    errorRead: () => dispatch(errorRead()),
    getModels: () => dispatch(doRequest('getModels')),
    getSystem: id => dispatch(doRequest('getSystem', id))
});

const _EditSystem = ({ data, match, errorRead, stateError, editSystem, getSystem, getModels }) => {
    const systemModel = {
        model: '',
        name: '',
        description: '',
        geodata: '',
        todo: '',
        state: '',
        pic: '',
        options: 0
    };

    const initialPayload = { ...systemModel };

    const [address, setAddress] = useState("");
    const [errors, setErrors] = useState("");
    const systemId = match.params.id || 0;
    const [processed, setProcessed] = useState(false);
    const [processed1, setProcessed1] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [permissions, setPermissions] = useState(0);
    const [system, setSystem] = useState(false);
    const [success, setSuccess] = useState(false);
    const [valid, setValid] = useState(true);
    const [brand, setBrand] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [models, setModels] = useState([]);
    const [processedModels, setProcessedModels] = useState(false);

    useEffect(
        () => {
            if(firstLoad) {
                getModels();
                setProcessedModels(true);
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, setProcessedModels, getModels]
    );

    useEffect(
        () => {
            if (!firstLoad && !processedModels && !processed1 && system === false) {
                getSystem(systemId);
                setProcessed1(true);
                setErrors("");
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError, processed1, setProcessed1, getSystem, systemId, system, processedModels, firstLoad]
    );

    const handlerChange = (e) => {
        let _payload = payload;
        if (e.target.type === 'radio') {
            if (e.target.checked) {
                _payload[e.target.name] = e.target.value;
            }
        } else if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                _payload[e.target.name] = parseInt(e.target.value);
            } else {
                _payload[e.target.name] = 0;
            }
        } else if (e.target.name === 'model') {
            _payload.system[e.target.name] = parseInt(e.target.value);
        } else {
            _payload[e.target.name] = e.target.value;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerGeodata = results => {
        let _payload = payload;
        _payload.geodata = JSON.stringify(results);
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        setSuccess(false);
        if(brand === 'lince-europlus' && !payload.model) {
            setErrors(`Selezionare modello impianto`); 
        } else {
            let v = SchemaValidator(payload, 'system_edit');
            if (v.errors.length > 0) {
                setErrors(v.errors.map(el => !!el.schema.message === true ? el.schema.message : null).filter(el => !!el).join("\n"));
            } else {
                setProcessed(true);
                editSystem({ ...payload, permissions }, systemId);
            }
        }
    }

    if (data && processed1) {
        setProcessed1(false);
        let _permissions = 0;
        setBrand(data.brand);
        delete data.brand;
        if (data.profiles.includes('manutentore')) {
            _permissions += 1;
        }
        if (data.profiles.includes('proprietario')) {
            _permissions += 2;
        }
        setPermissions(_permissions);
        if (_permissions === 0) {
            setSystem(null);
            return;
        }
        setSystem(data);
        let _payload = payload;
        for (let j in data) {
            if (_payload[j] != null) {
                _payload[j] = data[j];
            }
        }
        if (_permissions === 1) {
            _payload.pic = data.maintainer_pic;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
        const decodedGeodata = data.geodata ? JSON.parse(data.geodata) : null;
        setAddress(decodedGeodata && decodedGeodata[0] != null ? decodedGeodata[0].formatted_address : '');
        if (data.valid === 0) {
            setValid(false);
        }
    }

    if (data && processedModels) {
        setProcessedModels(false);
        setModels(JSON.parse(JSON.stringify(data)));
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
        setTimeout(() => {
            if(payload.also_maintainer != null) {
                setPermissions(payload.also_maintainer ? 3 : 2);
            }
        }, 1000);
    }

    if (!valid) {
        return (
            <section>
                <div className="innerSection">
                    <br />
                    <h3>Modifica impianto</h3>
                    <div className="rack box">
                        <div className="w100">
                            <blockquote>
                                Limite impianti raggiunto. Per gestire oltre tre impianti é necessario attivare l'account Premium.
                                <p>
                                    Scopri i vantaggi e <Link to={`/premium`}>abbonati ora</Link>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

        );
    }

    return (
        <section>
            <div className="innerSection">
                <br />
                <h3>Modifica impianto</h3>
                {
                    success
                        ? (
                            <Outcome text="Impianto salvato con successo" type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                {system && (
                    <System
                        system={{ ...system }}
                        handleChange={handlerChange}
                        handleSubmit={handlerSubmit}
                        lockMAC={true}
                        address={address}
                        handlerGeodata={handlerGeodata}
                        permissions={permissions}
                        models={models.map(model => model.brand === brand ? model : null).filter(x => !!x)}
                        modelRequired={(brand === 'lince-europlus')}
                        modelShow={(brand !== 'lince-europlus') ? (models.find(value => value.id === system.model)) : null}
                    />
                )}
                {system === null && (
                    <p style={{ padding: "2em" }}>Permessi non sufficenti per effettuare modifiche anagrafiche all'impianto</p>
                )}
            </div>
        </section>
    );
};

const EditSystem = connect(mapStateToProps, mapDispatchToProps)(_EditSystem);
export default EditSystem;