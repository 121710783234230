import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import get_bits from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
});

const _Opzioni = ({ system, socket, doSocketVariation, data_socket }) => {
    const codeForm = useRef(null);
    const trama = 0x00E6;
    const [current, setCurrent] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [processed, setProcessed] = useState(false);

    const status = !!system && !!socket ? system.store.status : null;

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, doSocketVariation, processed, setProcessed, socket]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let _current = {
                trama,
                payload: _x
            };
            setCurrent(_current);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox != 3) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Necessari permessi di Installatore`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = [];
        payload.push((
            (form['inserimento'].checked ? 1 : 0) +
            (form['led'].checked ? 2 : 0) +
            (form['autoreset'].checked ? 4 : 0) +
            (form['iuc'].checked ? 8 : 0) +
            (form['rit'].checked ? 16 : 0) +
            (form['cf'].checked ? 32 : 0) +
            (form['cb'].checked ? 64 : 0) +
            (form['iut'].checked ? 128 : 0)
        ));
        payload.push((
            (form['as'].checked ? 1 : 0)
        ));
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
    }

    if (!!!current || firstLoad) {
        return <>caricamento in corso...</>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            {current && (
                                <>
                                    <h3>Opzioni</h3>
                                    <br />
                                    <fieldset>
                                        <div className="rack switchRack">
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="inserimento" name="inserimento" defaultValue="0" defaultChecked={get_bits(current.payload[0], 0)} onChange={() => null} />
                                                    <label htmlFor={`inserimento`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Toni inserimento Centrale
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="iuc" name="iuc" defaultValue="0" defaultChecked={get_bits(current.payload[0], 3)} onChange={() => null} />
                                                    <label htmlFor={`iuc`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Toni Ingresso/Uscita Centrale
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="iut" name="iut" defaultValue="0" defaultChecked={get_bits(current.payload[0], 7)} onChange={() => null} />
                                                    <label htmlFor={`iut`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Toni Ingresso/Uscita Tastiera
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack switchRack">
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="led" name="led" defaultValue="0" defaultChecked={!get_bits(current.payload[0], 1)} onChange={() => null} />
                                                    <label htmlFor={`led`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        LED ON
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="cb" name="cb" defaultValue="0" defaultChecked={get_bits(current.payload[0], 6)} onChange={() => null} />
                                                    <label htmlFor={`cb`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Attivazione Chiave Base
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="autoreset" name="autoreset" defaultValue="0" defaultChecked={get_bits(current.payload[0], 2)} onChange={() => null} />
                                                    <label htmlFor={`autoreset`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Auto Reset
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack switchRack">
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="rit" name="rit" defaultValue="0" defaultChecked={get_bits(current.payload[0], 4)} onChange={() => null} />
                                                    <label htmlFor={`rit`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Ritardo 20' assenza rete 230 Vca
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="cf" name="cf" defaultValue="0" defaultChecked={get_bits(current.payload[0], 5)} onChange={() => null} />
                                                    <label htmlFor={`cf`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Allarme Chiave Falsa
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w33">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="as" name="as" defaultValue="0" defaultChecked={get_bits(current.payload[1], 0)} onChange={() => null} />
                                                    <label htmlFor={`as`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        Antisaturazione Radio
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setFirstLoad(true); }}>Annulla</button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Opzioni = connect(mapStateToProps, mapDispatchToProps)(_Opzioni);

export default Opzioni;