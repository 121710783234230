import React from 'react';

const StatoRilevatoreVolumetricoEsterno180 = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme ? "red" : "gray" }} /> Allarme</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_dx ? "red" : "gray" }} /> Allarme DX</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_frontale ? "#red" : "gray" }} /> Allarme Frontale</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "gray") }} /> Batteria Scarica</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.tamper ? "red" : "gray" }} /> Tamper</p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_am ? "red" : "gray" }} /> Allarme AM</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_sx ? "red" : "gray" }} /> Allarme SX</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "gray" }} /> Supervisione</p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "gray" }} /> Dormiente</p>
                </div>
            </div>
        </>
    );
};

export default StatoRilevatoreVolumetricoEsterno180;