import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { stringToArrayInt } from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
    setStore
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess)),
    setStore: data => dispatch(setStore(data))
});

const _Rf = ({ system, socket, doSocketVariation }) => {
    const radioForm = useRef(null);
    const [counter, setCounter] = useState(0);
    const [finished, setFinished] = useState(null);
    const [on, setOn] = useState(false);
    const [timer, setTimer] = useState(null);
    const [IDL, setIDL] = useState(0);
    const [IDH, setIDH] = useState(0);

    useEffect(
        () => {
            if (counter >= 512) {
                halt(timer);
                setFinished(0);
            }
        },
        [counter, setFinished, timer]
    );

    const umount = useCallback(() => {
        socket.off('onRfReceived');
        socket.emit('eventMemoryStop');
        clearInterval(timer);
    }, [timer, socket])

    useEffect(
        () => {
            socket.on('onRfReceived', data => {
                const payload = data.payload.split(',').map(x => parseInt(x));
                parseRf(payload, timer, setCounter);
            });
            return () => {
                umount();
                return true;
            }
        },
        [umount]
    );

    const start = e => {
        e.preventDefault();
        setOn(true);
        setFinished(null);
        setCounter(0);
        const interval = setInterval(
            () => {
                socket.emit('eventRf');
            },
            2000
        );
        setTimer(interval);
    }

    const halt = (_timer = null) => {
        socket.emit('eventMemoryStop');
        socket.off('onRfReceived');
        clearInterval(_timer || timer);
        setOn(false);
    }

    const stop = e => {
        e.preventDefault();
        halt();
    }

    const parseRf = (payload, interval, _setCounter) => {
        let sum = payload.reduce((sum, x) => sum + x);
        if (sum === 510) {
            _setCounter(counter => counter + 1);
        } else if (sum === 0) {
            halt(interval);
            setFinished(1);
        } else {
            halt(interval);
            let firstAvailable = null;
            for (let i = 0x0023; i <= 0x0062; i++) {
                let setting = system.store[i].split(',').map(x => parseInt(x));
                if (((setting[0] + setting[1]) === 0) || ((setting[0] + setting[1]) === 510)) {
                    firstAvailable = i;
                    break;
                }
            }
            if (firstAvailable != null) {
                setIDL(payload[0]);
                setIDH(payload[1]);
                setFinished(firstAvailable);
            } else {
                setFinished(2);
            }
        }
    }

    const makePayload = (name = null) => {
        const form = radioForm.current;
        const payload = [IDL, IDH];
        payload.push((
            (form['i2631'].checked ? 1 : 0) +
            (form['i2732'].checked ? 2 : 0) +
            (form['i2833'].checked ? 4 : 0) +
            (form['i2934'].checked ? 8 : 0) +
            (form['i3035'].checked ? 16 : 0) +
            (form['supervisionato'].checked ? 32 : 0) +
            (form['escluso'].checked ? 0 : 128)
        ));
        stringToArrayInt(16, (name != null ? name : form["nome"].value), 0x20).map(x => { payload.push(x); return null; });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, (0x0300 + finished), payload, true);
        let store = { [finished]: payload.join(',') };
        setStore(store);
        setCounter(0);
        setFinished(null);
        setOn(false);
        setTimer(null);
        setIDL(0);
        setIDH(0);
    }

    const status = !!system && !!socket ? system.store.status : null;

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 3) {
                return (
                    <Modal
                        title={`LinceCloud -> Impianto`}
                        text={`Necessari permessi di Installatore`}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={radioForm}>
                            <h3>Memorizzazione dispositivo RF</h3>
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w50">
                                        {on
                                            ? (
                                                <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} onClick={stop}>Interrompi</button>
                                            )
                                            : (
                                                <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} onClick={start}>Avvia</button>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100">
                                        {on && (<p className="loadingText">Ricerca dispositivo RF in corso</p>)}
                                        {finished === 0 && (<p>Non é stato memorizzato nessun dispositivo</p>)}
                                        {finished === 1 && (<p>Dispositivo già memorizzato</p>)}
                                        {finished === 2 && (<p>Dispositivo rilevato ma non sono disponibili ingressi radio</p>)}
                                        {finished >= 0x0023 &&
                                            (
                                                <div className="rack">
                                                    <hr />
                                                    <div className="w100 formSection">
                                                        <p>Dispositivo registrato. Impostare ingresso radio</p>
                                                        <h1>Parametri di Ingresso</h1>
                                                        <div className="rack">
                                                            <div className="w25">
                                                                <label>Nome</label>
                                                                <input type="text" name="nome" defaultValue={``} maxLength={16} />
                                                            </div>
                                                            <div className="w25">
                                                                <div className="switchButton">
                                                                    <input type="checkbox" className="switch" id="supervisionato" name="supervisionato" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                    <label htmlFor={`supervisionato`}>
                                                                        <span>
                                                                            <span />
                                                                        </span>
                                                                        Supervisionato
                                                                            </label>
                                                                </div>
                                                            </div>
                                                            <div className="w25">
                                                                <div className="switchButton">
                                                                    <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="1" defaultChecked={true} onChange={() => null} />
                                                                    <label htmlFor={`escluso`}>
                                                                        <span>
                                                                            <span />
                                                                        </span>
                                                                        Escluso
                                                                            </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h1>Associazioni a ingresso</h1>
                                                        <div className="rack bottomBorder">
                                                            <p>
                                                                <input type="checkbox" id="2631" name="i2631" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                <label htmlFor={`2631`}>
                                                                    <span />
                                                                    Ingresso filare associato: 26/31
                                                                            </label>
                                                            </p>
                                                            <p>
                                                                <input type="checkbox" id="2732" name="i2732" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                <label htmlFor={`2732`}>
                                                                    <span />
                                                                    Ingresso filare associato: 27/32
                                                                            </label>
                                                            </p>
                                                            <p>
                                                                <input type="checkbox" id="2833" name="i2833" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                <label htmlFor={`2833`}>
                                                                    <span />
                                                                    Ingresso filare associato: 28/33
                                                                            </label>
                                                            </p>
                                                            <p>
                                                                <input type="checkbox" id="2934" name="i2934" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                <label htmlFor={`2934`}>
                                                                    <span />
                                                                    Ingresso filare associato: 29/34
                                                                            </label>
                                                            </p>
                                                            <p>
                                                                <input type="checkbox" id="3035" name="i3035" defaultValue="0" defaultChecked={false} onChange={() => null} />
                                                                <label htmlFor={`3035`}>
                                                                    <span />
                                                                    Ingresso filare associato: 30/35
                                                                            </label>
                                                            </p>
                                                        </div>
                                                        <div className="w100 right">
                                                            <button className="ok auto spaced" onClick={handleSubmit}>Applica</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Rf = connect(mapStateToProps, mapDispatchToProps)(_Rf);

export default Rf;