import React from 'react';
import { connect } from 'react-redux';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system
});

const _StatoImpianto = ({ system }) => {
    const stato_centrale = [
        ['stato', 'servizio', 'blue', 'gray', 'Servizio'],
        ['stato', 'allarme_inserito', 'red', 'gray', 'Allarme Inserito'],
        ['alim', 'presenza_batteria_interna', 'red', 'green', 'Presenza Batteria Interna'],
        ['alim', 'stato_batteria_esterna', 'red', 'green', 'Stato Batteria Esterna'],
        ['alim', 'fusibile', 'red', 'green', 'Fusibile'],
        ['alim', 'rete_220_vca', 'red', 'green', 'Rete 220 Vca']
    ];
    const allarmi = [
        ['alim', 'allarme_a', 'red', 'gray', 'Allarme A'],
        ['alim', 'allarme_k', 'red', 'gray', 'Allarme K'],
        ['alim', 'allarme_tecnologico', 'red', 'gray', 'Allarme Tecnologico'],
        ['stato', 'sabotaggio_as_esterno', 'red', 'gray', 'Sabotaggio AS Esterno'],
        ['stato', 'sabotaggio_centrale', 'red', 'gray', 'Sabotaggio Centrale']
    ];
    const memorie = [
        ['stato', 'memoria_allarme_ingressi', 'yellow', 'gray', 'Memoria Allarme Ingressi'],
        ['stato', 'memoria_sabotaggio_ingresso', 'yellow', 'gray', 'Memoria Sabotaggio Ingresso'],
        ['stato', 'memoria_sabotaggio_as', 'yellow', 'gray', 'Memoria Sabotaggio AS'],
        ['stato', 'memoria_sabotaggio', 'yellow', 'gray', 'Memoria Sabotaggio ']
    ];
    const stato_ingressi = [
        ['ingr', 'supervisione_ingressi', 'red', 'gray', 'Supervisione Ingressi'],
        ['ingr', 'guasto_ingressi_radio', 'red', 'gray', 'Guasto Ingressi Radio'],
        ['ingr', 'sabotaggio_ingressi', 'red', 'gray', 'Sabotaggio Ingressi'],
        ['bus', 'dispositivo_bus_intruso', 'red', 'gray', 'Dispositivo BUS Intruso'],
        ['bus', 'sabotaggio_hw_bus', 'red', 'gray', 'Sabotaggio HW BUS'],
        ['bus', 'tamper_bus', 'red', 'gray', 'Tamper BUS'],
    ];

    if (!!!system.store || !!!system.store.state) {
        return <>caricamento in corso...</>;
    }

    const { state } = system.store;

    if (!state || !state.connesso) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Impianto non connesso`}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else if (!!!system.store || !!!system.store.auth) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h3>Stato Impianto</h3>
                <br />
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>Stato Centrale</h2>
                        <div className="padp">
                            {
                                stato_centrale.map((item, index) => (
                                    <p key={`stato_centrale_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>Allarmi</h2>
                        <div className="padp">
                            {
                                allarmi.map((item, index) => (
                                    <p key={`allarmi_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>Memorie</h2>
                        <div className="padp">
                            {
                                memorie.map((item, index) => (
                                    <p key={`memorie_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>Stato Ingressi</h2>
                        <div className="padp">
                            {
                                stato_ingressi.map((item, index) => (
                                    <p key={`stato_ingressi_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w100">
                        <h2>Informazioni</h2>
                        <div className="padp">
                            <div className="rack">
                                <div className="w50">
                                    <img src={`/static/images/model/${system.modello}.png`} alt={system.model} />
                                </div>
                                <div className="w50">
                                    <p><strong>Tensione Impianto: <br /><big>{(state.vbatt === '0.0' ? 'N.D.' : `${state.vbatt} V`)}</big></strong></p>
                                    <p><strong>Corrente Impianto: <br /><big>{(state.corrente === '0.0' ? 'N.D.' : `${state.corrente} A`)}</big></strong></p>
                                    <p><strong>ID Centrale <br /><big>{system.id_centrale}</big></strong></p>
                                    <p><strong>Versione FW: <br /><big>{state.fw_ver}</big></strong></p>
                                    <p><strong>Versione FW RF: <br /><big>{system.store.pm.rfversion}</big></strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoImpianto = connect(mapStateToProps)(_StatoImpianto);

export default StatoImpianto;