import React from 'react';
import { connect } from 'react-redux';

import get_bits, { arrayIntToString } from '../../../../lib/bytesFunctions';

const mapStateToProps = state => ({
    system: state.system,
});

const _StatoIngressiFilari = ({ system }) => {
    const status = !!system ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    const ingressiFilari = [];
    for (let i = 0; i <= 0x22; i++) {
        ingressiFilari.push(system.store[i]);
    }

    return (
        <section>
            <div className="innerSection">
                <h3>Stato Ingressi Filari</h3>
                <br />
                <div className="rack">
                    <div className="w100">
                        <form>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>Nome Ingresso</th>
                                            <th>Allarme 24h</th>
                                            <th>Ingresso Aperto</th>
                                            <th>Ingresso Escluso</th>
                                            <th>Memoria 24h</th>
                                            <th>Memoria Allarme</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {ingressiFilari.map((item, index) => {
                                            let name = arrayIntToString(item.split(',').slice(4, 19));
                                            let group = Math.floor(index / 8);
                                            let bit = index % 8;
                                            return (
                                                <tr key={`row_${index}`}>
                                                    <td><span className="head">Nome Ingresso</span>{`${index + 1} - ${!!name ? name : `Ingresso Filare ${index + 1}`}`}</td>
                                                    <td><span className="head">Allarme 24h</span>{(get_bits(status[`filari_oi24_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">Ingresso Aperto</span>{(get_bits(status[`filari_oi_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">Ingresso Escluso</span>{(get_bits(status[`filari_esclusioni_${group}`], bit) ? 'E' : '')}</td>
                                                    <td><span className="head">Memoria 24h</span>{(get_bits(status[`filari_memorie24_${group}`], bit) ? 'M' : '')}</td>
                                                    <td><span className="head">Memoria Allarme</span>{(get_bits(status[`filari_memorie_${group}`], bit) ? 'M' : '')}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoIngressiFilari = connect(mapStateToProps)(_StatoIngressiFilari);

export default StatoIngressiFilari;