import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSlider from 'react-slider';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bTel } from '../../../../lib/goldParser/converter';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

import { goldGetGsmStat, goldTestCall, sleep } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldSetPhoneNumbers: payload => dispatch(goldDoRequest('goldSetPhoneNumbers', payload)),
    setStore: data => dispatch(setStore(data))
});

const _NumeriTelefono = ({ match, data, errors, system, goldSetPhoneNumbers, setStore }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [ripetizioni, setRipetizioni] = useState(0);
    const [durataImpulso, setDurataImpulso] = useState(1);
    const [numeri, setNumeri] = useState([]);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);
    const [calling, setCalling] = useState(false);
    const [callError, setCallError] = useState(null);

    useEffect(() => {
        return async () => {
            startPolling();
            return true;
        }
    }, []);

    useEffect(
        () => {
            setFirstLoad(true);
            setSub((match.params.sub > 0 && match.params.sub <= 16) ? parseInt(match.params.sub) : 1);
            return () => null;
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    let _numeri = [];
                    for (let i = 0; i < 16; i++) {
                        _numeri.push({
                            idx: i,
                            ..._system.store.pm.tel[i]
                        });
                    }
                    setNumeri(_numeri);
                    setFirstLoad(false);
                    setCurrent(_numeri[(sub - 1)]);
                    setRipetizioni(_numeri[(sub - 1)].ripetizioni);
                    setDurataImpulso(_numeri[(sub - 1)].durata_impulso);
                }, 100);
            }
            return () => null;
        },
        [firstLoad]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Permessi insufficenti`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            ripetizioni: ripetizioni,
            sms_credito_scadenza: form.sms_credito_scadenza.checked,
            abilitazione: form.abilitazione.checked,
            vocale_a: form.vocale_a.checked,
            vocale_k: form.vocale_k.checked,
            vocale_sabotaggio: form.vocale_sabotaggio.checked,
            vocale_silenzioso: form.vocale_silenzioso.checked,
            sms_a: form.sms_a.checked,
            sms_k: form.sms_k.checked,
            sms_sabotaggio: form.sms_sabotaggio.checked,
            sms_silenzioso: form.sms_silenzioso.checked,
            sms_batteria_centrale_carica: form.sms_batteria_centrale_carica.checked,
            sms_batteria_radio_carica: form.sms_batteria_radio_carica.checked,
            sms_rete_elettrica_assente: form.sms_rete_elettrica_assente.checked,
            sms_rete_elettrica_ripristinata: form.sms_rete_elettrica_ripristinata.checked,
            sms_variazione_programmi: form.sms_variazione_programmi.checked,
            sms_accesso_sistema: form.sms_accesso_sistema.checked,
            squillo_esistenza_vita: form.squillo_esistenza_vita.checked,
            sms_esistenza_vita: form.sms_esistenza_vita.checked,
            squillo_conferma_uscite: current.squillo_conferma_uscite,
            sms_conferma_uscite: current.sms_conferma_uscite,
            vocale_conferma_uscite: current.vocale_conferma_uscite,
            apri_cancello_na: form.apri_cancello_na.checked,
            apri_cancello_out1: form.apri_cancello_out1.checked,
            impulsato: form.impulsato.checked,
            durata_impulso: durataImpulso,
            numero: form.numero.value,
            nome: form.nome.value
        };
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/phone/${value}`);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldSetPhoneNumbers({
            id_centrale: system.id_centrale,
            idx: current.idx,
            edata: j2bTel(payload)
        });
        setProcessed(true);
    }

    const handleReset = e => {
        e.preventDefault();
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(numeri[idx]), 100);
    };

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    const startPolling = () => {
        setStore({
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_pause: true
        });
    };

    const pollCall = async () => {
        let prevGsmState = 0;
        let k = 0;
        while (k < 30) {
            await sleep(3000);
            let [__data, __code] = await goldGetGsmStat({ id_centrale: system.id_centrale });
            if (__code === 200 && __data.status === 'OK') {
                if (__data.stato_gsm != 2 && prevGsmState === 2) {
                    setCalling(false);
                    startPolling();
                    break;
                } else {
                    prevGsmState = parseInt(__data.stato_gsm);
                }
            } else {
                setCalling(false);
                startPolling();
                setCallError("Si è verificato un errore. Riprovare");
                break;
            }
            k++;
        }
    };

    const startCall = async e => {
        e.preventDefault();
        stopPolling();
        setCalling(true);
        await sleep(1500);
        const [__data, __code] = await goldTestCall({ id_centrale: system.id_centrale, pos: current.idx });
        if (__code === 200 && __data.status === 'OK') {
            await pollCall();
        } else {
            setCallError("Impossibile effettuare la chiamata di test. Riprovare");
            setCalling(false);
            startPolling();
        }
    };

    if (!!!current) {
        return <>caricamento in corso....</>;
    }

    if (data && processed) {
        setProcessed(null);
        const _success = !!data && !errors;
        if (_success) {
            const form = codeForm.current;
            const numero = form.numero.value;
            const _current = clone(current);
            _current.numero = numero;
            setCurrent(_current);
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            <h3>Numeri di Telefono</h3>
                            <br />
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <label>Scelta numero di telefono</label>
                                        <select defaultValue={(current.idx)} onChange={handleSelectChange}>
                                            {
                                                numeri.map((value, index) => <option key={(index)} value={index}>{`${index + 1} - ${value.nome}`}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">
                                        <label>Nome</label>
                                        <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="abilitazione" name="abilitazione" defaultValue="0" defaultChecked={current.abilitazione} />
                                            <label htmlFor={`abilitazione`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Numero abilitato
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">
                                        <label>Numero</label>
                                        <input type="text" name="numero" defaultValue={current.numero} maxLength={16} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_credito_scadenza" name="sms_credito_scadenza" defaultValue="0" defaultChecked={current.sms_credito_scadenza} />
                                            <label htmlFor={`sms_credito_scadenza`}>
                                                <span>
                                                    <span />
                                                </span>
                                                SMS Credito/Scadenza
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                    <div className="w50">
                                        <p>Ripetizioni messaggio vocale</p>
                                        <ReactSlider
                                            defaultValue={current.ripetizioni}
                                            onChange={value => { setRipetizioni(parseInt(value)); }}
                                            max={16}
                                            min={0}
                                            step={1}
                                            className="horizontal-slider"
                                            thumbClassName="slider-thumb"
                                            trackClassName="slider-track"
                                            renderThumb={(props, state) => {
                                                return (<div {...props}>{`${state.valueNow}`}</div>);
                                            }}
                                        />
                                    </div>
                                </div>
                                <h4>Vocali Allarmi</h4>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="vocale_a" name="vocale_a" defaultValue="0" defaultChecked={current.vocale_a} />
                                            <label htmlFor={`vocale_a`}>
                                                <span>
                                                    <span />
                                                </span>
                                                A
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="vocale_k" name="vocale_k" defaultValue="0" defaultChecked={current.vocale_k} />
                                            <label htmlFor={`vocale_k`}>
                                                <span>
                                                    <span />
                                                </span>
                                                K
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w30">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="vocale_sabotaggio" name="vocale_sabotaggio" defaultValue="0" defaultChecked={current.vocale_sabotaggio} />
                                            <label htmlFor={`vocale_sabotaggio`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Sabotaggio
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w30">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="vocale_silenzioso" name="vsilenzioso_antipanico" defaultValue="0" defaultChecked={current.vocale_silenzioso} />
                                            <label htmlFor={`vocale_silenzioso`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Silenzioso/Antipanico
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h4>SMS Allarmi</h4>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_a" name="sms_a" defaultValue="0" defaultChecked={current.sms_a} />
                                            <label htmlFor={`sms_a`}>
                                                <span>
                                                    <span />
                                                </span>
                                                A
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_k" name="sms_k" defaultValue="0" defaultChecked={current.sms_k} />
                                            <label htmlFor={`sms_k`}>
                                                <span>
                                                    <span />
                                                </span>
                                                K
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w30">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_sabotaggio" name="sms_sabotaggio" defaultValue="0" defaultChecked={current.sms_sabotaggio} />
                                            <label htmlFor={`sms_sabotaggio`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Sabotaggio
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w30">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_silenzioso" name="sms_silenzioso" defaultValue="0" defaultChecked={current.sms_silenzioso} />
                                            <label htmlFor={`sms_silenzioso`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Silenzioso/Antipanico
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h4>SMS Eventi Alimentazione e Generali</h4>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_batteria_centrale_carica" name="sms_batteria_centrale_carica" defaultValue="0" defaultChecked={current.sms_batteria_centrale_carica} />
                                            <label htmlFor={`sms_batteria_centrale_carica`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Batteria Centrale Carica/Scarica
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_batteria_radio_carica" name="sms_batteria_radio_carica" defaultValue="0" defaultChecked={current.sms_batteria_radio_carica} />
                                            <label htmlFor={`sms_batteria_radio_carica`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Batteria RF Carica/Scarica
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_rete_elettrica_assente" name="sms_rete_elettrica_assente" defaultValue="0" defaultChecked={current.sms_rete_elettrica_assente} />
                                            <label htmlFor={`sms_rete_elettrica_assente`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Rete Elettrica Assente
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_rete_elettrica_ripristinata" name="sms_rete_elettrica_ripristinata" defaultValue="0" defaultChecked={current.sms_rete_elettrica_ripristinata} />
                                            <label htmlFor={`sms_rete_elettrica_ripristinata`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Rete Elettrica Ripristinata
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_variazione_programmi" name="sms_variazione_programmi" defaultValue="0" defaultChecked={current.sms_variazione_programmi} />
                                            <label htmlFor={`sms_variazione_programmi`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Variazione Programmi
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_accesso_sistema" name="sms_accesso_sistema" defaultValue="0" defaultChecked={current.sms_accesso_sistema} />
                                            <label htmlFor={`sms_accesso_sistema`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Accesso al Sistema
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h4>Esistenza in vita</h4>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="sms_esistenza_vita" name="sms_esistenza_vita" defaultValue="0" defaultChecked={current.sms_esistenza_vita} />
                                            <label htmlFor={`sms_esistenza_vita`}>
                                                <span>
                                                    <span />
                                                </span>
                                                SMS
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="squillo_esistenza_vita" name="squillo_esistenza_vita" defaultValue="0" defaultChecked={current.squillo_esistenza_vita} />
                                            <label htmlFor={`squillo_esistenza_vita`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Squillo
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h4>Apricancello</h4>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="apri_cancello_na" name="apri_cancello_na" defaultValue="0" defaultChecked={current.apri_cancello_na} />
                                            <label htmlFor={`apri_cancello_na`}>
                                                <span>
                                                    <span />
                                                </span>
                                                +N+A
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="apri_cancello_out1" name="apri_cancello_out1" defaultValue="0" defaultChecked={current.apri_cancello_out1} />
                                            <label htmlFor={`apri_cancello_out1`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Out
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w20">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="impulsato" name="impulsato" defaultValue="0" defaultChecked={current.impulsato} />
                                            <label htmlFor={`impulsato`}>
                                                <span>
                                                    <span />
                                                </span>
                                                Impulso
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <p>Durata Impulso</p>
                                        <ReactSlider
                                            defaultValue={current.durata_impulso}
                                            onChange={value => { setDurataImpulso(parseInt(value)); }}
                                            max={99}
                                            min={0}
                                            step={1}
                                            className="horizontal-slider"
                                            thumbClassName="slider-thumb"
                                            trackClassName="slider-track"
                                            renderThumb={(props, state) => {
                                                return (<div {...props}>{`${state.valueNow}`}</div>);
                                            }}
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    {calling && <p className="loadingText">Test chiamata in corso</p>}
                                    {callError && <p>{callError}</p>}
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={startCall} disabled={current.numero.length === 0 || calling || system.store.state.conn_type === 'signal'}>Test</button>
                                        {system.store.state.conn_type === 'signal' && (
                                            <p style={{ marginRight: '1em' }}>
                                                <em><small>Non è possibile effettuare chiamate di test con connessione GPRS</small></em>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset} disabled={calling}>Annulla</button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={system.store.state.prog_active || calling}>Applica</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const NumeriTelefono = connect(mapStateToProps, mapDispatchToProps)(_NumeriTelefono);

export default NumeriTelefono;