import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { USCITE } from '../../../../constants/gold';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bUscite } from '../../../../lib/goldParser/converter';

import {
    goldDoRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldOut: (requests, payloads) => dispatch(goldDoRequest(requests, payloads))
});

const _Uscite = ({ data, errors, system, goldOut }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(!!_system.store ? _system.store.pm.uscite : null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);

    const polarity = ['normale', 'invertita'];
    const outputs = [
        { name: _system.out1 || "OUT 1", key: "out1" },
        { name: _system.out2 || "OUT 2", key: "out2" },
        { name: _system.out3 || "OUT 3", key: "out3" },
        { name: _system.out4 || "OUT 4", key: "out4" },
        { name: "+N +A", key: "na" }
    ];

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 2) {
        return (
            <Modal
                title={`LinceCloud -> Impianto`}
                text={`Necessari permessi da installatore`}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current) {
        return <>caricamento in corso...</>;
    }
    const makePayload = () => {
        const form = codeForm.current;
        return {
            uscita0: { attributo: form.attributo0.value, polarita: form.polarita0.value },
            uscita1: { attributo: form.attributo1.value, polarita: form.polarita1.value },
            uscita2: { attributo: form.attributo2.value, polarita: form.polarita2.value },
            uscita3: { attributo: form.attributo3.value, polarita: form.polarita3.value },
            uscita4: { attributo: form.attributo4.value, polarita: form.polarita4.value }
        };
    };

    const handleReset = e => {
        e.preventDefault();
        setCurrent(null);
        setTimeout(() => setCurrent(_system.store.pm.uscite), 100);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        const requests = ['goldModOut'];
        const payloads = [{
            id_centrale: system.id_centrale,
            edata: j2bUscite(payload)
        }];
        goldOut(requests, payloads);
        setProcessed(true);
    }

    if (data && processed) {
        setProcessed(null);
        setSuccess(!!data && !errors);
    }

    return (
        <section>
            {success === true && <Modal title={`Impianto -> LinceCloud`} text={`Comando inviato con successo`} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={`Impianto -> LinceCloud`} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <h3>Uscite</h3>
                            <br />
                            <fieldset>
                                <div className="rack">
                                    {outputs.map((output, idx) => (
                                        <div className="w50" key={`output_${idx}`}>
                                            <h4>{output.name}</h4>
                                            <div className="rack">
                                                <div className="w50">
                                                    <label>Polarità</label>
                                                    <select defaultValue={current[`uscita${idx}`].polarita} name={`polarita${idx}`}>
                                                        {polarity.map((x, idx2) => (
                                                            <option value={x} key={`option_${idx2}`}>{x}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="w50">
                                                    <label>Attributi</label>
                                                    <select defaultValue={current[`uscita${idx}`].attributo} name={`attributo${idx}`}>
                                                        {USCITE.map((x, i) => (
                                                            <option value={i}>{x}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack" style={{ marginTop: "4em" }}>
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>Annulla</button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.state.prog_active)}>Applica</button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Uscite = connect(mapStateToProps, mapDispatchToProps)(_Uscite);

export default Uscite;